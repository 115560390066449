import { roleGuard } from "@/helper/router.helper";

export default {
  routes: [
    {
      path: "/profile",
      name: "profile",
      component: () => import("./UserProfile.vue"),
      meta: {
        title: "UPPER_NAVBAR.PROFILE",
        roles: ["admin", "template_moderator", "authorized_employee"],
      },
      beforeEnter: roleGuard,
    },
  ],
};
