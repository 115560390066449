import axios from "axios";
import router from "@/router";

const URL = import.meta.env.VITE_BASE_API_URL;
const LANG = localStorage.getItem("localeLang");

const axiosClient = axios.create({
  baseURL: URL,
  headers: {
    Accept: "application/json",
    lang: LANG,
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "*",
    withCredentials: true,
  },
});

axiosClient.interceptors.request.use((config) => {
  const token = $cookies.get("token_wtheq_app");
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

axiosClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      process.env.VITE_ENV == "dev" ||
      process.env.VITE_ENV == "stg" ||
      process.env.VITE_ENV == "prod" ||
      process.env.VITE_ENV == "uat"
    ) {
      console.clear();
    }
    if (error.response?.status === 401) {
      $cookies.remove("token_wtheq_app");
      router.push({ name: "sign-in" });
    }

    if (error.response?.status === 404) {
      // router.replace({ name: "not-found" });
    }

    // if (error.response?.status === 403) {
    //   router.push("/");
    // }

    return Promise.reject(error);
  }
);

export default axiosClient;
