export default {
  routes: [
    {
      path: "",
      component: () => import("./WrapperNICAuthorized.vue"),
      children: [
        {
          path: "/nic-authorized",
          name: "nic-authorized",
          component: () => import("./NICAuthorized.vue")
        },
        {
          path: "/nic-authorized-success",
          name: "nic-authorized-success",
          component: () => import("./NICAuthorizedSuccess.vue")
        },
        {
          path: "/nic-authorized-fail",
          name: "nic-authorized-fail",
          component: () => import("./NICAuthorizedFail.vue")
        }
      ]
    }
  ]
};
