import ApiClient from "@/helper/api.helper";

class UserProfile extends ApiClient {
  constructor() {
    super("/api/profile");
  }

  uploadAvatar(data) {
    return axios.post(`${import.meta.env.VITE_BASE_API_URL}/api/update`, data);
  }
}

export default new UserProfile();
