import { defineAsyncComponent } from "vue";

const components = [
  {
    name: "Input",
    component: defineAsyncComponent(() =>
      import("@/components/common/FiledInput.vue")
    ),
  },
  {
    name: "Button",
    component: defineAsyncComponent(() =>
      import("@/components/common/MainButton.vue")
    ),
  },
  {
    name: "Select",
    component: defineAsyncComponent(() =>
      import("@/components/common/SelectInput.vue")
    ),
  },
  {
    name: "Breadcrumbs",
    component: defineAsyncComponent(() =>
      import("@/components/common/BreadCrumbs.vue")
    ),
  },
  {
    name: "DataTable",
    component: defineAsyncComponent(() =>
      import("@/components/common/DataTable.vue")
    ),
  },
  {
    name: "ListCards",
    component: defineAsyncComponent(() =>
      import("@/components/common/ListCards.vue")
    ),
  },
  {
    name: "PhoneField",
    component: defineAsyncComponent(() =>
      import("@/components/common/PhoneField.vue")
    ),
  },
];

export default {
  install: function (app) {
    components.forEach((component) => {
      const name = `Wb${component.name}`;
      app.component(name, component.component);
    });
  },
};
