import {roleGuard} from "@/helper/router.helper";
import createTemplatesRoute from "./CreateTemplates/create.templates.route";

export default {
    routes: [
        {
            path: "/template",
            component: () => import("./WrapperTemplates.vue"),
            redirect: {name: "template-list"},
            children: [
                {
                    component: () => import("./TemplatesList.vue"),
                    path: "",
                    name: "template-list",
                    meta: {
                        title: "SIDEBAR.TEMPLATES",
                        pageType: "SIDEBAR.TEMPLATES",
                        roles: ["admin", "template_moderator", "authorized_employee"],
                    },
                    beforeEnter: roleGuard,
                },
                {
                    path: "create",
                    name: "create-template",
                    component: () => import("./CreateTemplates/WrapperCreate.vue"),
                    meta: {
                        title: "SIDEBAR.TEMPLATES",
                        pageType: "SIDEBAR.employee_type",
                        roles: ["admin", "template_moderator", "authorized_employee"],
                        wide: true
                    },
                    beforeEnter: roleGuard,

                    redirect: {name: "template-create-information"},
                    children: [...createTemplatesRoute.routes],
                },
                {
                    path: "/template/edit/:id",
                    name: "edit-template",
                    component: () => import("./EditTemplate.vue"),
                    meta: {
                        title: "SIDEBAR.TEMPLATES",
                        pageType: "SIDEBAR.employee_type",
                        roles: ["admin", "template_moderator", "authorized_employee"],
                        wide: true
                    },
                    beforeEnter: roleGuard,
                },
                {
                    path: "/template/preview/:id",
                    name: "preview-template",
                    component: () => import("./TemplatePreview.vue"),
                    meta: {
                        title: "SIDEBAR.TEMPLATES",
                        pageType: "SIDEBAR.employee_type",
                        roles: ["admin", "template_moderator", "authorized_employee"],
                        wide: true
                    },
                    beforeEnter: roleGuard,
                },
            ],
        },
    ],
};
