import Pusher from "pusher-js";
import Echo from "laravel-echo";
window.Pusher = Pusher;
const token = $cookies.get("token_wtheq_app");

const echo = new Echo({
  broadcaster: "pusher",
  cluster: "mt1",
  key: "4068hg9d72",
  wsHost: "soketi-prod.watheq.co",
  forceTLS: false,
  encrypted: false,
  wsPort: 443,
  wssPort: 443,
  authEndpoint: `${import.meta.env.VITE_BASE_API_URL}/api/broadcasting/auth`,
  auth: {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  },
  enabledTransports: ["ws", "wss"],
});

export default echo;
